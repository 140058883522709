// @flow
import * as React from 'react'

const VolumeIcon = ({ style, muted = false, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', fill: 'white', ...style }}
  >
    {muted ? (
      <g>
        <path d="M10.567,17.675L17.4,22.8c0.176,0.133,0.388,0.2,0.6,0.2c0.152,0,0.306-0.035,0.447-0.105 C18.786,22.725,19,22.379,19,22V9.242L10.567,17.675z" />
        <path d="M2,17h5L19,5V2c0-0.379-0.214-0.725-0.553-0.895C18.109,0.938,17.703,0.973,17.4,1.2L9.667,7H2 C1.447,7,1,7.447,1,8v8C1,16.553,1.447,17,2,17z" />
        <path d="M1,24c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l22-22 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-22,22C1.512,23.902,1.256,24,1,24z" />
      </g>
    ) : (
      <g>
        <path d="M14.447,1.105C14.109,0.938,13.703,0.973,13.4,1.2L5.667,7H1C0.447,7,0,7.447,0,8v8c0,0.553,0.447,1,1,1 h4.667l7.733,5.8c0.176,0.133,0.388,0.2,0.6,0.2c0.152,0,0.306-0.035,0.447-0.105C14.786,22.725,15,22.379,15,22V2 C15,1.621,14.786,1.275,14.447,1.105z" />
        <path d="M18.243,7.758l-0.707-0.707l-1.414,1.414l0.707,0.707c1.56,1.56,1.56,4.097,0,5.656 l-0.707,0.707l1.414,1.414l0.707-0.707C20.582,13.903,20.582,10.097,18.243,7.758z" />
        <path d="M20.364,4.222L18.95,5.636l0.707,0.707c3.119,3.119,3.119,8.195,0,11.314l-0.707,0.707 l1.414,1.414l0.707-0.707c3.899-3.899,3.899-10.243,0-14.143L20.364,4.222z" />
      </g>
    )}
  </svg>
)

export default VolumeIcon
