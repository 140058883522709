// @flow

export function showInFullscreen(iframe: HTMLElement) {
  const requestFullScreen =
    // $FlowFixMe
    iframe.requestFullScreen ||
    // $FlowFixMe
    iframe.mozRequestFullScreen ||
    // $FlowFixMe
    iframe.webkitRequestFullScreen

  if (requestFullScreen) {
    // todo: test on diff browsers
    // $FlowFixMe
    requestFullScreen.bind(iframe)()
  }
}
