// @flow
import * as React from 'react'

const FullScreenIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', fill: 'white', ...style }}
  >
    <g>
      <path d="M23,2H1A1,1,0,0,0,0,3V21a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V3A1,1,0,0,0,23,2ZM10,18H4V12H6v4h4Zm10-6H18V8H14V6h6Z" />
    </g>
  </svg>
)

export default FullScreenIcon
