// @flow
import * as React from 'react'
import styled from 'styled-components'
import { durations } from '../../styles/animations'

type Props = {
  children?: React.Node,
}

const ButtonStyle = styled.div`
  background: none;
  padding: 10px;
  outline-offset: 0;
  cursor: pointer;
`

const Inner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: currentColor;
  transition: color ${durations.fast} ease-out;
`

/**
 * Wrapper that creates a button that centers an icon (or similar item)
 **/
function IconButton({ children, ...rest }: Props) {
  return (
    <ButtonStyle role="button" aria-label={'icon button'} {...rest}>
      <Inner>{children}</Inner>
    </ButtonStyle>
  )
}

IconButton.displayName = 'IconButton'
IconButton.defaultProps = {}

export default IconButton
