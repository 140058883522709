// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQuery } from '../../styles/media'
import { durations } from '../../styles/animations'
import { showInFullscreen } from '../../utils/videoPlayerUtil'
import { InView } from 'react-intersection-observer'

import type { ImageViewModel } from '../../types/ImageViewModel'

import Image from '../../components/Image/Image'
import IconButton from '../../components/IconButton/IconButton'
import PlayIcon from '../../icons/PlayIcon'
import FullScreenIcon from '../../icons/FullScreenIcon'
import PauseIcon from '../../icons/PauseIcon'
import Playback from './Playback'
import VolumeIcon from '../../icons/VolumeIcon'
import Ribbon from '../Ribbon/Ribbon'

type Props = {
  src: string,
  poster?: ImageViewModel,
  autoPlay?: boolean,
  controls?: boolean,
  muted?: boolean,
  onReady?: Function,
  onPlay?: Function,
  onPause?: Function,
  onEnd?: Function,
  onMountVideo?: Function,
  loop?: boolean,
  caption?: string,
  // decides if Playback Controls should be placed in Ribbon
  useControlsRibbon?: boolean,
}

type State = {
  started: boolean,
  paused: boolean,
  title: string,
  isMuted: boolean,
  playerId: string,
  fullscreenPlayerId: string,
  seconds: number,
  percent: number,
  duration: number,
}

type PlaybackState = {
  seconds: number,
  percent: number,
  duration: number,
}

const Wrapper = styled.div`
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  overflow-y: hidden;
`

const Foreground = styled.div`
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 300%;
`

const StyledVideo = styled.div`
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
  }
`

const FullScreenPlaceholder = styled.div`
  position: absolute;

  /* should be rendered out of the viewport */
  top: -10000px;
`

const PlaybackWrapper = styled.div`
  position: absolute;
  bottom: ${rem(15)};
  width: 100%;

  transform: translateY(${rem(80)});

  ${mediaQuery.sm`
    transition: transform ${durations.slow} ease-out;
    
    ${Wrapper}:hover & {
      transform: translateY(0%);
    }
  `};

  ${mediaQuery.lg`
    bottom: ${rem(30)};
  `}
`

const PlaybackControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${rem(48)};

  ${mediaQuery.md`
    padding: 0 ${rem(92)};
  `}
`

const ClickableOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledIconButton = styled(IconButton)`
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`

const StyledPlayButton = styled.button`
  opacity: 1;
  z-index: 2;
  border: none;
  width: auto;
  height: auto;
  padding: 10px;
  line-height: 0;
  cursor: pointer;

  /* sets svg size */
  font-size: 64px;

  &:focus-visible {
    border: 1px dashed #fff;
    outline: none;
  }

  ${mediaQuery.sm`
    opacity: 0.6;
  `}

  ${ClickableOverlay}:hover & {
    opacity: 1;
  }
`

const PosterImage = styled(Image)`
  z-index: 1;
`

class Vimeo extends React.PureComponent<Props, State> {
  static displayName = 'Vimeo'
  static defaultProps = {
    autoPlay: false,
    loop: false,
    controls: false,
    muted: false,
    useControlsRibbon: false,
  }

  player = undefined
  fullscreenPlayer = undefined

  container: { current: null | HTMLDivElement } = React.createRef()
  videoRef: { current: null | HTMLDivElement } = React.createRef()
  videoFullsizeRef: { current: null | HTMLDivElement } = React.createRef()

  constructor(props: Props) {
    super(props)

    this.state = {
      started: !!props.autoPlay,
      paused: !props.autoPlay,
      title: '',
      isMuted: !!props.muted,
      playerId: `vimeo-${props.src}`,
      fullscreenPlayerId: `fullscreen-vimeo-${props.src}`,
      seconds: 0,
      percent: 0,
      duration: 0,
    }
  }

  componentDidMount() {
    const { onMountVideo, onReady } = this.props
    const { playerId, fullscreenPlayerId } = this.state

    const setDuration = duration => {
      this.setState({ duration })
    }

    this.embedVideo(playerId, false, player => {
      if (!!player) {
        // setup initial mounted video
        this.player = player
        // $FlowFixMe
        this.player.on('play', this.handlePlay)
        // $FlowFixMe
        this.player.on('pause', this.handlePause)
        // $FlowFixMe
        this.player.on('timeupdate', this.handleTimeUpdate)

        // $FlowFixMe
        this.player.getDuration().then(function(duration) {
          setDuration(duration)
        })

        if (typeof onReady === 'function') {
          onReady()
        }

        if (typeof onMountVideo === 'function') {
          // send the internal player back to the caller
          onMountVideo(this.getInternalPlayer())
        }
      }
    })

    // we need to embed a new video, because the 'original' video
    // may not have controls active, and we have no way to change that
    // config once the video is embedded
    this.embedVideo(fullscreenPlayerId, true, player => {
      if (!!player) {
        this.fullscreenPlayer = player
      }
    })
  }

  embedVideo(
    playerId: string,
    withControls: boolean = false,
    callback: Function,
  ) {
    const { src, loop, muted } = this.props

    if (!playerId || !src) {
      return
    }

    // vimeo options
    // docs: https://github.com/vimeo/player.js#embed-options
    const options = {
      url: src,
      background: !withControls,
      loop,
      muted,
      playsinline: false,
    }

    // mount the vimeo player
    const Player = require('@vimeo/player/dist/player') // prevent failling tests on SSR https://github.com/vimeo/player.js/issues/32#issuecomment-255742059
    const player = new Player(playerId, options)

    // return the player to the callback
    player
      .ready()
      .then(() => {
        if (typeof callback === 'function') {
          callback(player)
        }
      })
      .catch(err => {})
  }

  // simple api for the player
  // mocks the methods for the same names as youtube
  getInternalPlayer = () => {
    if (this.player) {
      return {
        playVideo: () => this.player && this.player.play(),
        pauseVideo: () => this.player && this.player.pause(),
        mute: () => this.player && this.player.setVolume(0),
        unMute: () => this.player && this.player.setVolume(0.5), // maybe it should remember the mute setting?
        setCurrentTime: (value: number) =>
          this.player && this.player.setCurrentTime(value),
        isMuted: () =>
          new Promise<boolean>((resolve, reject) => {
            if (!this.player) {
              return resolve(true)
            }

            this.player.getVolume().then(function(volume) {
              resolve(volume === 0)
            })
          }),
        triggerFullscreen: this.handleFullscreenClick,
      }
    }

    return undefined
  }

  handlePlay = () => {
    if (!this.state.started && !this.props.autoPlay && this.player) {
      this.player.pause() // pause first render right away
      this.setState({ started: true, paused: true })
    } else {
      if (!this.state.isMuted && this.player && this.player.setVolume) {
        this.player.setVolume(0.5)
      }
      if (this.props.onPlay) this.props.onPlay()
      this.setState({ started: true, paused: false })
    }
  }

  handlePause = () => {
    if (this.props.onPause) this.props.onPause()
    this.setState({ paused: true })
  }

  handleTimeUpdate = (playbackState: PlaybackState) => {
    this.setState({
      seconds: playbackState.seconds,
      percent: playbackState.percent,
      duration: playbackState.duration,
    })
  }

  handlePlayClick = () => {
    if (this.player) {
      this.player.getPaused().then(paused =>
        paused
          ? // $FlowFixMe
            this.player.play()
          : // $FlowFixMe
            this.player.pause(),
      )
    }
  }

  handleFullscreenClick = () => {
    const { fullscreenPlayerId } = this.state
    if (this.fullscreenPlayer && this.player) {
      this.player.pause()

      // $FlowFixMe
      const iframe = this.fullscreenPlayer.element
      // $FlowFixMe
      this.fullscreenPlayer.setVolume(0.8)
      if (iframe) {
        showInFullscreen(iframe)
        return
      }

      // didn't find any iframe
      // lets log it for debugging
      console.log(`unable to find iframe for ${fullscreenPlayerId}`) // eslint-disable-line no-console
    }
  }

  handleMuteClick = () => {
    const playerInstance = this.getInternalPlayer()
    if (playerInstance) {
      // todo: check if promise
      const isMutedPromise = playerInstance.isMuted()
      isMutedPromise.then(muted => {
        this.setState({ isMuted: !muted })
        return muted ? playerInstance.unMute() : playerInstance.mute()
      })
    }
  }

  handleInViewStateChange = (inView: boolean) => {
    const windowHeight = window.innerHeight
    const playerHeight = this.container.current
      ? this.container.current.getBoundingClientRect().height
      : windowHeight
    const playerInstance = this.getInternalPlayer()

    if (playerInstance && this.props.autoPlay) {
      // prevent not playing video in a short window
      if (playerHeight >= windowHeight) {
        return playerInstance.playVideo()
      }

      inView ? playerInstance.playVideo() : playerInstance.pauseVideo()
    }
  }

  handleProgressChange = (value: number) => {
    const playerInstance = this.getInternalPlayer()
    if (playerInstance) {
      // convert percentage to seconds
      const newTime = this.state.duration * (value / 100)
      playerInstance.setCurrentTime(newTime)
    }
  }

  render() {
    const {
      started,
      playerId,
      fullscreenPlayerId,
      paused,
      duration,
      percent,
      seconds,
      isMuted,
    } = this.state

    const {
      src,
      controls,
      poster,
      useControlsRibbon,
      caption,
      ...rest
    } = this.props
    if (!src) return null

    return (
      <InView
        tag={'div'}
        onChange={this.handleInViewStateChange}
        threshold={0.7}
      >
        <Wrapper ref={this.container} {...rest}>
          <Foreground>
            <StyledVideo id={playerId} ref={this.videoRef} />
          </Foreground>
          <FullScreenPlaceholder
            id={fullscreenPlayerId}
            ref={this.videoFullsizeRef}
          />
          <ClickableOverlay onClick={this.handlePlayClick}>
            {(!started || paused) && this.player && (
              <StyledPlayButton title={caption}>
                <PlayIcon />
              </StyledPlayButton>
            )}
            {(!started || paused) && poster && (
              <PosterImage {...poster} fillContainer />
            )}
          </ClickableOverlay>

          {controls && (
            <PlaybackWrapper>
              {useControlsRibbon ? (
                <Ribbon marginBottom={false}>
                  <PlaybackControlsContainer style={{ padding: 0 }}>
                    <StyledIconButton onClick={this.handlePlayClick}>
                      {started && !paused ? (
                        <PauseIcon style={{ fontSize: '40px' }} />
                      ) : (
                        <PlayIcon style={{ fontSize: '40px' }} />
                      )}
                    </StyledIconButton>

                    <Playback
                      percent={percent * 100}
                      seconds={seconds}
                      duration={duration}
                      onProgressChange={this.handleProgressChange}
                    />

                    <StyledIconButton onClick={this.handleMuteClick}>
                      <VolumeIcon muted={isMuted} />
                    </StyledIconButton>
                    <StyledIconButton onClick={this.handleFullscreenClick}>
                      <FullScreenIcon />
                    </StyledIconButton>
                  </PlaybackControlsContainer>
                </Ribbon>
              ) : (
                <PlaybackControlsContainer>
                  <StyledIconButton onClick={this.handlePlayClick}>
                    {started && !paused ? (
                      <PauseIcon style={{ fontSize: '40px' }} />
                    ) : (
                      <PlayIcon style={{ fontSize: '40px' }} />
                    )}
                  </StyledIconButton>

                  <Playback
                    percent={percent * 100}
                    seconds={seconds}
                    duration={duration}
                    onProgressChange={this.handleProgressChange}
                  />

                  <StyledIconButton onClick={this.handleMuteClick}>
                    <VolumeIcon muted={isMuted} />
                  </StyledIconButton>
                  <StyledIconButton onClick={this.handleFullscreenClick}>
                    <FullScreenIcon />
                  </StyledIconButton>
                </PlaybackControlsContainer>
              )}
            </PlaybackWrapper>
          )}
        </Wrapper>
      </InView>
    )
  }
}

export default Vimeo
