// @flow
import * as React from 'react'

const PauseIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 40 40"
    {...rest}
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', fill: 'white', ...style }}
  >
    <g>
      <circle id="Combined-Shape" fill="#FFFFFF" cx="20" cy="20" r="20" />
      <path
        d="M17,13 L14,13 C13.4,13 13,13.4 13,14 L13,26 C13,26.6 13.4,27 14,27 L17,27 C17.6,27 18,26.6 18,26 L18,14 C18,13.4 17.6,13 17,13 Z"
        id="Path"
        fill="#003851"
      />
      <path
        d="M26,13 L23,13 C22.4,13 22,13.4 22,14 L22,26 C22,26.6 22.4,27 23,27 L26,27 C26.6,27 27,26.6 27,26 L27,14 C27,13.4 26.6,13 26,13 Z"
        id="Path"
        fill="#003851"
      />
    </g>
  </svg>
)

export default PauseIcon
