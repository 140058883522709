// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { color } from '../../styles/theme'
import { getFontSize } from '../../styles/style-helpers'

import Slider from 'rc-slider'

type Props = {
  duration?: number,
  seconds?: number,
  percent?: number,
  onProgressChange?: Function,
}

type State = {
  progress: number,
}

const PlaybackContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Timer = styled.p`
  flex: 1 1 10%;
  color: ${color.white};
  font-size: ${getFontSize('tiny')};
  text-align: center;
  font-weight: bold;
  margin: 0 ${rem(10)};
  height: 1em;
`

const StyledSlider = styled(Slider)`
  position: relative;
  transition: all 0.1s ease;
  flex: 1 1 80%;
  height: 100%;
  display: flex;
  align-items: center;
`

class Playback extends React.PureComponent<Props, State> {
  state = {
    progress: 0,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.percent !== this.props.percent) {
      this.setState({ progress: this.props.percent }) // eslint-disable-line
    }
  }

  handleSliderChange = (value: number) => {
    this.setState({
      progress: value,
    })

    if (this.props.onProgressChange) {
      this.props.onProgressChange(value)
    }
  }

  buildPlaybackTimeString = (timeInSeconds?: number) => {
    if (!timeInSeconds) return '00:00'

    // solution from https://stackoverflow.com/a/25279340
    const time = new Date(0)
    time.setSeconds(timeInSeconds)
    return time.toISOString().substr(14, 5)
  }

  render() {
    const { seconds, duration } = this.props

    return (
      <PlaybackContainer>
        <Timer>{this.buildPlaybackTimeString(seconds)}</Timer>
        <StyledSlider
          value={this.state.progress}
          onChange={this.handleSliderChange}
        />
        <Timer>
          {this.buildPlaybackTimeString(Math.ceil(Number(duration)))}
        </Timer>
      </PlaybackContainer>
    )
  }
}

export default Playback
